import { usePostTrackEventWithParams } from "./analyticsQueries"

export type TGuardAssistEvents =
  | "Guard Assist Landing Page EmptyState"
  | "Guard Assist Provider Selected"
  | "Guard Assist Sign Up Initiated"
  | "Guard Assist Home Select Submitted"
  | "Guard Assist Home Address Submitted"
  | "Guard Assist Address Map Submitted"
  | "Guard Assist Emergency Contact Submitted"
  | "Guard Assist Activate Submitted"
  | "Guard Assist Add Addtional Home Initiated"

export function usePostTrackGuardAssistEvents(event: TGuardAssistEvents) {
  return usePostTrackEventWithParams<
    TGuardAssistEvents,
    {
      userId: string
      homeId?: string
      orgId: string
      providerId?: string
    }
  >({ event })
}
